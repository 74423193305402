export interface ExternalLogin {
  returnTo: string;
  token: string;
}

export function isSafeReturnUrl(url: string | null): url is string {
  if (!url) {
    return false;
  }
  try {
    const urlObj = new URL(url);
    return (
      urlObj.hostname.split('.').slice(-2).join('.') === 'fountain.com' ||
      urlObj.hostname === 'localhost'
    );
  } catch {
    return false;
  }
}
