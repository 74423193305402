import {
  shift as Shift,
  timesheet as Timesheet,
} from '@fountain/wx-api-clients/generated/attendance';

export enum RuleType {
  Blocker = 'blocker',
  Warning = 'warning',
}

export enum RuleReferenceType {
  Shift = 'shift',
  Worker = 'worker',
  Timesheet = 'timesheet',
}

export type Rule = {
  message: string;
  referenceType?: 'shift' | 'worker' | 'timesheet';
  referenceUuid: string;
};

export type RuleWithType = Rule & { ruleType?: RuleType };

export type Rules = {
  blockers: Rule[];
  warnings: Rule[];
};

export type RulesWithShift = Rules & {
  locationUuid?: string;
  shiftUuid: string;
};

export type RulesWithTimesheet = {
  timesheetUuid?: string;
  blockers: Rule[];
  warnings: Rule[];
};

export interface ShiftWithRules extends Shift {
  blockers?: RuleWithType[];
  warnings?: RuleWithType[];
}

export interface TimesheetWithRules extends Timesheet {
  blockers?: RuleWithType[];
  warnings?: RuleWithType[];
}

export const enum SpotlightFilterOptionTypes {
  All = 'all',
  Unassigned = 'unassigned',
  Published = 'published',
  Edited = 'edited',
  Blocker = 'blocker',
  Warning = 'warning',
  Deleted = 'deleted',
}

export enum JobRelationship {
  Primary = 'primary',
  Secondary = 'secondary',
}

export type JobIdentifier = {
  jobUuid?: string;
  relationship?: 'primary' | 'secondary';
};
